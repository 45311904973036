import React from "react"
import { Link, graphql } from "gatsby"
import MainLayout from "../components/mainlayout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import headerImage1 from "../../src/images/positive_life_header.png";
import headerImage2 from "../../src/images/background.jpg";
import headerImage3 from "../../src/images/background.jpg";
import headerImage4 from "../../src/images/health_and_life_header.png";
import headerImage5 from "../../src/images/background.jpg";
/*import headerImage2 from "../../src/images/background_image_site2.png";*/
import Moment from 'react-moment';
import 'moment-timezone';
import SiteInfo from '../components/siteinformation';

export default ({ data }) => {
  return (
    <MainLayout>
      <SEO title="home" />
      <div class="container-fluid px-0">
        &nbsp;
      </div>
        <div class="container-fluid px-0">
            <div class="row">
                <div class="col-xs-12"         style={{
                  width: "100%"
                }}>
                    <img src={getBackgroundImage()} alt="placeholder 960" class="img-responsive" />
                </div>
            </div>
        </div>

        <section class="recipes-section spad pt-0">
            <div class="container">
                <div class="section-title">
                    <h2>Blog Posts</h2>
                </div>
                <div class="row">
      {data.allWordpressPost.edges.map(({ node }) => (
          // if (node.featured_media.local)
                    <div class="col-lg-4 col-md-6">
                        <div class="recipe">
                            <Img resolutions={node.featured_media.localFile.childImageSharp.resolutions} key={node.featured_media.localFile.childImageSharp.resolutions.src} />
                            <div class="recipe-info-warp">
                                <div class="recipe-info">
                                    <h3> <Link to={node.slug}>{node.title}</Link></h3>
                                    <div class="rating">
                                        <Moment format="YYYY/MM/DD HH:MM">{getPostDate(node.date)}</Moment>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </div>

      ))}
                </div>
            </div>
        </section>

    </MainLayout>
  )
}

function getPostDate(date) {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return new Date( (new Date(date)).getTime() - 24*60*60*1000  - 60*1000 * 8.7);
    }
    if (site == 3) {
        return new Date( (new Date(date)).getTime() - 29*60*60*1000  - 60*1000 * 2.4);
    }
    if (site == 4) {
        return new Date( (new Date(date)).getTime() - 12*60*60*1000  - 60*60*1000 * .58);
    }
    if (site == 5) {
        return new Date( (new Date(date)).getTime() - 6*60*60*1000  - 60*1000 * 22.7);
    }
    return date;
}

function getBackgroundImage() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return headerImage2;
    }
    if (site == 3) {
        return headerImage3;
    }
    if (site == 4) {
        return headerImage4;
    }
    if (site == 5) {
        return headerImage5;
    }

    return headerImage1;
}




export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          featured_media {
            localFile {
              childImageSharp {
                resolutions(width: 300, traceSVG: {}) {
                  ...GatsbyImageSharpResolutions
                }
              }
            }
          }
        }
      }
    }
  }

`